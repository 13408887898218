@import 'variables';
@import 'buttons';
@import 'grid';
@import 'typography';
@import 'forms';

html {
  min-height: 100%;
  display: flex;
}
html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: $fontfamily-primary;
  font-size: 16px;
  line-height: 24px;
  padding: 106px 0 0 0;
  flex: 1;
  color: $color-darkgray;
}
@media (max-width: 991px) {
  body {
    padding: 70px 0 0 0;
  }
  body.fixed {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

#root {
  height: 100%;
}

strong {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontfamily-secondary;
  font-weight: 500;
  display: flex;
  align-items: center;
}
h1 {
  font-size: 42px;
  line-height: 52px;
}
h2 {
  font-size: 36px;
  line-height: 42px;
}
h3 {
  font-size: 30px;
  line-height: 38px;
}
h4 {
  font-size: 26px;
  line-height: 34px;
}
h5 {
  font-size: 22px;
  line-height: 30px;
  img{
    width: auto;
    height: 22px;
  }
}
h6 {
  font-size: 18px;
  line-height: 26px;
}

.plain {
  font-family: $fontfamily-primary;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 300;
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}

p {
  font-size: 16px;
  line-height: 24px;
}

a {
  font-size: 16px;
  line-height: 24px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $color-black;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $color-black;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $color-black;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $color-black;
}

.spin {
  -webkit-animation: spin 0.7s infinite linear;
  animation: spin 0.7s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.transition {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}

.blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  -ms-filter: blur(8px);
  -moz-filter: blur(8px);
}

.progress {
  display: block;
  position: relative;
  width: 100%;
  .bar {
    display: block;
    position: relative;
    height: 7px;
    border-radius: 3px;
    background: $color-light;
    > div {
      display: block;
      position: absolute;
      border-radius: 3px;
      top: 0;
      left: 0;
      bottom: 0;
      background: #6693cf;
      background: -moz-linear-gradient(left, #6693cf 0%, #02fcff 100%);
      background: -webkit-linear-gradient(left, #6693cf 0%, #02fcff 100%);
      background: linear-gradient(to right, #6693cf 0%, #02fcff 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6693cf', endColorstr='#02fcff',GradientType=1 );
      >img{
        width: 30px;
        min-width: 30px;
        position: absolute;
        top:-10px;
        right:-15px;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(0,0,0,.2);
      }
    }
  }
  .legend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    font-family: $fontfamily-primary;
    font-weight: 300;
    text-transform: uppercase;
    padding-top: 20px;
    >div{
      display: flex;
      flex:1;
      justify-content: center;
      &:first-child{
        justify-content: flex-start;
      }
      &:last-child{
        justify-content: flex-end;
      }
    }
  }
}

.innerLayout {
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: 991px) {
    flex-direction: column;
  }

  > .main {
    padding: 50px 0 50px 40px;
    min-height: 1400px;
    width: 100%;
    max-width: 800px;
    @media (max-width: 991px) {
      padding: 30px;
    }
    @media (max-width: 767px) {
      padding: 0;
    }
    > .container {
      max-width: 815px;
      @media (max-width: 1199px) {
        max-width: 725px;
      }
      @media (max-width: 991px) {
        max-width: 100%;
      }
    }
  }

  > .dashboard {
    width: calc(100% - ((100% - 1170px) / 2 + 300px));
    min-height: 1400px;
    @media (max-width: 991px) {
      width: 100%;
    }
    > .welcome {
      padding: 50px 0 50px 40px;
      text-align: center;
      @media (max-width: 991px) {
        padding: 30px;
        // padding:30px 30px 150px 30px;
      }
      @media (max-width: 767px) {
        padding: 30px;
        // padding:30px 15px 150px 15px;
      }
      > .container {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 815px;
        @media (max-width: 1199px) {
          max-width: 725px;
        }
        @media (max-width: 991px) {
          max-width: 100%;
        }
      }
      background: $color-darkgray;
      // height: 400px;
      height: 300px;
      // padding-bottom: 150px;
      color: $color-white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h5 {
        letter-spacing: 2px;
        font-size: 10px;
        font-family: $fontfamily-primary;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    > .main {
      // margin-top: -150px;
      padding: 50px 0 50px 40px;
      @media (max-width: 991px) {
        padding: 30px;
      }
      @media (max-width: 767px) {
        padding: 30px 0;
      }
      .container {
        max-width: 815px;
        @media (max-width: 1199px) {
          max-width: 725px;
        }
        @media (max-width: 991px) {
          max-width: 100%;
        }
      }

      .boxes {
        > .container {
          margin-left: 0;
        }
        .box {
          background: $color-white;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 30px;
          @media (max-width: 767px) {
            padding: 20px;
          }

          &.box1 {
            margin-top:-100px;
            background: #fff url('../images/box1bg.svg') center 90px no-repeat;
            @media (max-width: 767px) {
              margin-top:-50px;
            }
            .my-status {
              margin: 40px 0;
              text-align: center;
              width: 100%;
              padding-top: 50px;
              h5 {
                letter-spacing: 2px;
                font-size: 10px;
                font-family: $fontfamily-primary;
                margin: 0 !important;
                line-height: 14px;
                font-weight: 600;
                text-transform: uppercase;
              }
              h1 {
                font-size: 46px;
                font-family: $fontfamily-primary;
                font-weight: 300;
                margin: 10px 0 0 0;
                @media (max-width: 767px) {
                  font-size: 32px;
                }
              }
            }
            .progress {
              max-width: 90%;
            }
          }

          &.box2 {
            .row.d-flex{
              width: 100%;
            }
            .circle {
              >div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 50px 25px;
                background:url('../images/circle_gradient.svg') center no-repeat;
                background-size: contain;
              }
            }

            .sectagon {
              >div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 50px 25px;
                background:url('../images/sectagon_gradient.svg') center no-repeat;
                background-size: contain;
              }
            }

            .circle,
            .sectagon{
              h5 {
                letter-spacing: 2px;
                font-size: 10px;
                font-family: $fontfamily-primary;
                margin: 0 !important;
                line-height: 14px;
                font-weight: 600;
                text-transform: uppercase;
              }
              h1 {
                font-size: 40px;
                margin: 10px 0 0 0;
                > small {
                  font-size: 16px;
                  font-family: $fontfamily-primary;
                  font-weight: 400;
                  padding-right: 5px;
                }
              }
            }
          }

          &.box3 {
            .row {
              width: 100%;
            }
            h5 {
              display: block;
              letter-spacing: 2px;
              font-size: 7px;
              font-family: $fontfamily-primary;
              color: $color-muted-text;
              font-weight: 600;
              text-transform: uppercase;
            }
            h1 {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 38px;
              font-family: $fontfamily-primary;
              font-weight: 400;
              text-align: center;
              > small {
                font-size: 16px;
                padding-right: 5px;
              }
            }
          }
        }
      }

      .ordersTable {
        .head {
          border-bottom: 2px solid $color-light;
        }
        .body {
          border-bottom: 2px dotted $color-light;
        }
        > .row {
          @media (max-width: 767px) {
            padding: 10px 0;
          }
          > div {
            padding: 10px;
            font-size: 14px;
            @media (max-width: 767px) {
              padding: 5px 0;
            }
          }
        }
      }
    }
  }
}

.ErrorPage {
  h1 {
    font-family: $fontfamily-primary;
    font-size: 172px;
    line-height: 172px;
  }
}



.csTable{
  margin-right: 15px;
  margin-left:-40px;
  font-size: 14px;
  @media(max-width:991px){
    margin-right: -30px;
    margin-left: -30px;
  }
  @media(max-width:767px){
    margin-right: 0px;
    margin-left: 0px;
  }
  .top{
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 2px  solid rgba(0,0,0,.1);
    @media(max-width:991px){
      padding-left: 30px;
      padding-right: 30px;
    }
    @media(max-width:767px){
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0;
    }
    select{
      appearance:none;
      -webkit-appearance:none;
      -moz-appearance:none;
      -ms-appearance:none;
      font-size: 14px;
      padding: 10px 20px;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAACqADAAQAAAABAAAABgAAAAD+iFX0AAAAPUlEQVQIHW2MAQoAIAjEzv7/50pDWZmQwd2cSZr7+dj52o5+IM4DRCUpC6E0X1mGbmFBazAEf/DbUxDmZl8vlAoC2qo9XwAAAABJRU5ErkJggg==') no-repeat;
      background-position: calc(100% - 15px) 15px;
      border-radius: 20px !important;
      border: 1px  solid rgba(0,0,0,.5);
      min-width: 200px;
    }
  }
  .head{
    border-top: 1px  solid rgba(0,0,0,.1);
    border-bottom: 1px  solid rgba(0,0,0,.1);
    padding-top: 30px;
    @media(max-width:767px){
      display: none;
    }
    >div{
      padding: 20px;
      &:first-child{
        @media(max-width:991px){
          padding-left: 40px;
        }
      }
      &:last-child{
        @media(max-width:991px){
          padding-left: 40px;
        }
      }
    }
  }
  .body{
    border-bottom: 1px  solid rgba(0,0,0,.1);
    @media(max-width:767px){
      flex-direction: column;
      border-bottom: none;
      border-top: 1px  solid rgba(0,0,0,.1);
      padding-top: 15px;
      padding-bottom: 15px;
    }
    >div{
      padding: 20px;
      @media(max-width:767px){
        padding: 5px 15px;
      }
      &:first-child{
        @media(max-width:991px){
          padding-left: 40px;
        }
        @media(max-width:767px){
          padding-left: 15px;
        }
      }
      &:last-child{
        @media(max-width:991px){
          padding-right: 40px;
        }
        @media(max-width:767px){
          padding-right: 15px;
          display: flex;
          flex-wrap: wrap;
          flex-basis: 100%;
        }
      }
    }
  }

  .badge{
    padding: 5px 20px;
    background: $color-lightgray;
    border-radius: 15px;
    white-space: nowrap;
    margin-left: 5px;
    @media(max-width:767px){
      margin-left: 0;
      margin-right: 5px;
      padding: 4px 15px;
      margin-bottom: 4px;
    }

    &.ownerrights{
      background: $color-darkgray2;
      color:$color-white;
    }
    &.brc{
      background: $color-clay;
      color:$color-white;
    }
    &.buyer{
      background: #a1c5d3;
      color:$color-white;
    }
  }
}




/* Tooltip container */
.tooltip {
  position: relative;
  display: flex;
  margin:0;
  padding: 0;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  display: inline-block;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 15px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;

  &:before{
    position: absolute;
    content: "";
    top:10px;
    left:-10px;
    border-right:10px solid black;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  top: -5px;
  left: calc(100% + 10px);
}
