.button,
a.button,
a.button:link{
  display: flex;
  text-transform: lowercase;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color:$color-darkgray;
  background: $color-light;
  font-family: $fontfamily-secondary;
  letter-spacing: 1px;
  font-weight: 500;
  padding:10px 15px;
  border:1px solid transparent;
  @include _transition;

  &.blank{
    background: transparent;
    border-color: transparent;
  }

  &.w-200{
    width:200px;
  }

  &.lg{
    font-size: 14px;
    padding:15px 20px;
    min-width: 125px;
  }

  &.fullWidth{
    width:100%;
  }

  > svg{
    width:16px;
    height: 16px;
    margin-right: 5px;
  }

  &.accent{
    color:$color-white;
    background: $color-accent;
  }

  &.beige{
    color:$color-black;
    background: $color-white;
  }

  &.white{
    background: $color-white;
    border-color: $color-white;
    color:$color-dark;
    &.outline{
      background: transparent;
      color:$color-white;
    }
  }

  &.rounuded{
    border-radius: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }

  &.radius{
    border-radius: 40px;
    padding-left: 25px;
    padding-right: 25px;
  }

  &:hover{
    color:$color-white;
    background: $color-darkgray;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus,
  &:active{
    color:$color-white;
    background: $color-black;
      outline: none;
  }

  &.dark{
    color:$color-white;
    background: $color-darkgray;
    border-color: $color-darkgray;
    &.outline{
      background: transparent;
      color:$color-darkgray;
    }
  }

}


.iconButton{
  display: inline-block;
  border: none;
  background: none;
  padding:5px;
  margin:2px;
  width: 30px;
  height: 30px;
  color:$color-darkgray;
  >svg{
    width:18px;
    height:18px;
    opacity: .7;
  }
  &:hover{
    cursor: pointer;
    >svg{
      opacity: 1;
    }
  }
  &:focus{
    outline: none;
  }
}
