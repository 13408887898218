@font-face {
  font-family: 'Big Caslon';
  src: url('../fonts/Big-Caslon-Regular.woff') format('woff'),
       url('../fonts/Big Caslon Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../fonts/HelveticaNeueLTStd-Roman.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../fonts/HelveticaNeueLTStd-Lt.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../fonts/HelveticaNeueLTStd-Bd.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../fonts/HelveticaNeueLTStd-Th.woff') format('woff');
  font-weight: 200;
}



$color-accent: #333f49;

$color-lightgray: #f2f2f2;
$color-gray: #808080;
$color-light: #EAE9E9;
$color-white: #fff;
$color-black: #000;
$color-dark: #000;
$color-darkgray: #30383b;
$color-darkgray2: #cbcdcc;
$color-skyblue: #A1B5CC;
$color-clay: #D4C6BD;
$color-blue: #b5cbdd;
$color-muted-text: #a4a4a4;

$color-red: #dd0000;
$color-green: #009900;
$color-orange: #ff9900;

$fontfamily-primary: "Helvetica Neue Light", sans-serif;
$fontfamily-secondary: "Big Caslon", serif;;


@mixin _transition(){
  transition:all 0.3s ease;
  -webkit-transition:all 0.3s ease;
  -ms-transition:all 0.3s ease;
  -moz-transition:all 0.3s ease;
}


:root {
  --blue: #5D94D6;
  --indigo: #2d1582;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ed4c78;
  --orange: #fd7e14;
  --yellow: #f5ca99;
  --green: #28a745;
  --teal: #00c9a7;
  --cyan: #09a5be;
  --white: #fff;
  --gray: #D3D5D7;
  --gray-dark: #71869d;
  --primary: #5B6670;
  --secondary: #5B6670;
  --success: #00c9a7;
  --info: #09a5be;
  --warning: #f5ca99;
  --danger: #ed4c78;
  --light: #f7faff;
  --dark: #1e2022;
  --indigo: #2d1582;
  --navy: #5B6670;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Helvetica Neue Light", sans-serif;
  --font-family-serif: BigCaslon,Book Antiqua,Palatino Linotype,Georgia,serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
