@import "../../assets/scss/variables";

.pagination{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  padding:20px 0;

  .total{
    text-align: left;
    font-size: 14px;
    color:$color-muted-text;
  }
  .actions{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    button{
      border:none;
      background: none;
      padding:15px;
      cursor: pointer;
      &.prev{
        background: url('../../assets/images/right-chevron.svg') center no-repeat;
        background-size: 15px auto;
        transform: rotate(180deg);
      }
      &.next{
        background: url('../../assets/images/right-chevron.svg') center no-repeat;
        background-size: 15px auto;
      }
    }
  }
}
