.form-group{
  width:100%;
  padding:5px 0;
  margin-bottom: 10px;

  label{
    font-size: 11px;
    display: block;
    text-transform: uppercase;
    opacity: .7;
  }
}

.form-control{
  display: block;
  width:100%;
  padding:10px;
  border: 1px  solid $color-light;
  font-family: $fontfamily-primary;
  font-size: 14px;


  &:disabled{
    background: rgba(0,0,0,.03);
    color: rgba(0,0,0,.5);
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    opacity: .5;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    opacity: .5;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    opacity: .5;
  }
  &:-moz-placeholder { /* Firefox 18- */
    opacity: .5;
  }

  &:focus{
    border-color: $color-dark;
    outline: none;
  }
}

select{
  &.form-control{
    appearance:none;
    -webkit-appearance:none;
    -moz-appearance:none;
    -ms-appearance:none;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAACqADAAQAAAABAAAABgAAAAD+iFX0AAAAPUlEQVQIHW2MAQoAIAjEzv7/50pDWZmQwd2cSZr7+dj52o5+IM4DRCUpC6E0X1mGbmFBazAEf/DbUxDmZl8vlAoC2qo9XwAAAABJRU5ErkJggg==') no-repeat;
    background-position: calc(100% - 15px) 15px;
    padding:10px;
    border-radius: 0 !important;
    border: 1px  solid $color-light;
  }
}

.switch{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  >div{
    display: block;
    position: relative;
    width:50px;
    height: 24px;
    background: $color-light;
    margin-right: 10px;
    border-radius: 20px;
    @include _transition;

    > i{
      position: absolute;
      display: block;
      top:3px;
      left:3px;
      width:18px;
      height:18px;
      border-radius: 14px;
      background: $color-white;
      @include _transition;
    }
  }

  input{
    position: absolute;
    z-index: 9;
    top:0;
    left:0;
    widtH:100%;
    height:100%;
    opacity: 0;

    &:hover{
      cursor: pointer;
    }

    &:checked + div{
      background: $color-dark;
    }

    &:checked + div> i{
      left:auto;
      left:28px;
    }
  }
}
